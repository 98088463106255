<template>
  <div class="personal">
    <el-menu :default-active="personalIndex" class="personal-menu">
      <el-menu-item
        v-for="tab in tabList"
        :key="tab.index"
        :index="tab.index"
        :ref="tab.ref"
        @click="handleClickTab(tab)"
      >
        <img class="slide_icon" :src="tab.image_url" />
        <span slot="title">{{ tab.name }}</span>
      </el-menu-item>
    </el-menu>
    <div class="personal-right">
      <div class="my_info" v-if="currentTab == 1">
        <div class="userInfo-box">
          <!-- <el-radio-group
            v-model="userActiveTab"
            size="small"
            style="position: absolute; left: 15px; top: 6vh"
          >
            <el-radio-button label="基本信息"></el-radio-button>
            <el-radio-button label="权限信息"></el-radio-button>
          </el-radio-group> -->
          <el-tabs
            v-model="userActiveTab"
            class="starTab"
            style="margin: 10px 0 0 0"
          >
            <el-tab-pane label="基本信息" name="基本信息"></el-tab-pane>
            <!-- <el-tab-pane label="权限信息" name="权限信息"></el-tab-pane> -->
          </el-tabs>
          <div
            class="user"
            style="
              width: 60px;
              height: 60px;
              margin-bottom: 10px;
              border: 3px solid gray;
              margin-top: 15px;
            "
          >
            <i class="el-icon-user" style="font-size: 35px"></i>
          </div>
          <p style="margin-bottom: 40px">
            {{ userInfoParams.username }}
          </p>
          <div class="formInfoBox">
            <el-form
              ref="form"
              :model="userInfoParams"
              v-if="userActiveTab == '基本信息'"
            >
              <div class="tableTitle">
                <el-form-item style="color:#fff" label="账号有效期：">
                  {{ effective_time }} 到期
                </el-form-item>
              </div>
              <div class="tableContent">
                <div class="tableContentOdd">
                  <div class="contentBorder">
                    <el-form-item label="真实姓名">
                      <el-input
                        v-model="userInfoParams.real_name"
                        size="small"
                        placeholder="请输入姓名"
                      ></el-input>
                    </el-form-item>
                  </div>

                  <el-form-item label="企业邮箱">
                    <el-input
                      v-model="userInfoParams.email"
                      size="small"
                      placeholder="请输入企业邮箱"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="tableContentEven">
                  <div class="contentBorder">
                    <el-form-item label="性别">
                      <!-- <el-input
                      v-model="userInfoParams.sex"
                      size="small"
                      placeholder="请输入性别"
                    ></el-input> -->
                      <div style="padding-left:10px;">
                        <el-radio v-model="userInfoParams.sex" label="男"
                          >男</el-radio
                        >
                        <el-radio v-model="userInfoParams.sex" label="女"
                          >女</el-radio
                        >
                      </div>
                    </el-form-item>
                  </div>

                  <el-form-item label="手机">
                    <el-input
                      v-model="userInfoParams.mobile"
                      size="small"
                      placeholder="请输入手机"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="tableContentOdd">
                  <div class="contentBorder">
                    <el-form-item label="所属企业">
                      <el-input
                        v-model="userInfoParams.company_name"
                        size="small"
                        placeholder="请输入所属企业"
                      ></el-input>
                    </el-form-item>
                  </div>

                  <el-form-item label="所属部门">
                    <el-input
                      v-model="userInfoParams.department"
                      size="small"
                      placeholder="请输入所属部门"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="tableContentEven">
                  <div class="contentBorder">
                    <el-form-item label="职位">
                      <el-input
                        v-model="userInfoParams.post"
                        size="small"
                        placeholder="请输入职位"
                      ></el-input>
                    </el-form-item>
                  </div>

                  <el-form-item label="居住地">
                    <div class="place">
                      <el-input
                        v-model="userInfoParams.province"
                        size="small"
                        placeholder="请输入居住地省份"
                      ></el-input>
                      <p>省</p>
                      <el-input
                        v-model="userInfoParams.city"
                        size="small"
                        placeholder="请输入居住地市"
                      ></el-input>
                      <p>市</p>
                    </div>
                  </el-form-item>
                </div>
                <div class="tableContentOddlast">
                  <el-form-item label="感兴趣的产业">
                    <el-input
                      v-model="userInfoParams.interest_industry"
                      size="small"
                      placeholder="请输入感兴趣的产业"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="tableContentEvenlast">
                  <el-form-item label="感兴趣的企业">
                    <el-input
                      v-model="userInfoParams.interest_company"
                      size="small"
                      placeholder="请输入感兴趣的企业"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>

              <!--               
              <el-form-item label="企业名">
                <el-input
                  v-model="userInfoParams.company_name"
                  style="width: 90%"
                  size="small"
                  placeholder="请输入企业名"
                ></el-input>
              </el-form-item>
              <el-form-item label="岗位">
                <el-input
                  v-model="userInfoParams.post"
                  style="width: 90%"
                  size="small"
                  placeholder="请输入岗位"
                ></el-input>
              </el-form-item>
              
              <el-form-item label="电话">
                <el-input
                  v-model="userInfoParams.mobile"
                  style="width: 90%"
                  size="small"
                  placeholder="请输入电话"
                ></el-input>
              </el-form-item> -->
            </el-form>
          </div>

          <!-- <el-form
            v-if="userActiveTab == '权限信息'"
            ref="form"
            label-width="120px"
            style="margin-top: 40px"
          >
            <el-table
              :data="userTableData"
              border
              style="width: 391px; margin-left: 20px"
            >
              <el-table-column label="模块" width="150">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <p size="medium">{{ scope.row.perm_name }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="effective_time"
                label="过期时间"
                width="240"
              >
              </el-table-column>
            </el-table>
          </el-form> -->
        </div>
        <div v-if="userActiveTab == '基本信息'">
          <el-button
            size="small"
            type="primary"
            style="position: absolute; right: 50px; bottom: 20px"
            @click="changeUserInfo"
            >保存</el-button
          >
        </div>
      </div>
      <div
        class="my_subscribe"
        v-if="currentTab == 2"
        v-loading="subscribeLoading"
      >
        <el-tabs
          v-model="SubscribeActiveTab"
          class="starTab"
          style="margin: 10px 0 0 0"
        >
          <!-- <el-tab-pane label="基本信息" name="基本信息"></el-tab-pane> -->
          <el-tab-pane label="订阅列表" name="订阅列表"></el-tab-pane>
        </el-tabs>
        <div class="tabBlue">
          <!-- <div class="tabBlue-item">全部 {{ subscribeTotal }}</div>
            <div class="tabBlue-item">企业 {{ companyCount }}</div>
            <div class="tabBlue-item">产业 {{ industryCount }}</div> -->
          <div
            :class="
              tabBlueActive == tab.index
                ? 'tabBlue-item_selected'
                : 'tabBlue-item'
            "
            v-for="tab in tabBlueList"
            :key="tab.index"
            @click="handleClickTabBlue(tab)"
          >
            {{ tab.name }} {{ tab.count }}
          </div>
        </div>
        <div
          class="subscribe-item"
          v-for="sub in subscribeList"
          :key="sub.name"
        >
          <p class="subscribe-name" @click="toSubscribeContent(sub)">
            {{ sub.name }}
          </p>
          <el-switch v-model="sub.status" @change="cancelSubscribe(sub)">
          </el-switch>
        </div>
      </div>
      <div class="my_star" v-if="currentTab == 3">
        <el-tabs
          v-model="starActive"
          @tab-click="handleStarActive"
          class="starTab"
          style="margin: 10px 0 0 1%"
        >
          <el-tab-pane label="新闻" name="news"></el-tab-pane>
          <el-tab-pane label="招聘" name="post"></el-tab-pane>
          <el-tab-pane label="专利" name="patent"></el-tab-pane>
          <!-- <el-tab-pane label="公司" name="company"></el-tab-pane> -->
        </el-tabs>
        <div
          class="personal-content"
          v-loading="starLoading"
          style="height: 80vh"
        >
          <NewsList
            v-if="starActive == 'news'"
            :apiDataNewsList="starNewsList"
            :hasKeyword="false"
            :page="starParams.page"
            :page_size="starParams.page_size"
            :total="star_total"
            @changePage="handlePageChange"
            @cancelStar="handleCancelStar"
            :isFavorites="true"
          ></NewsList>
          <PostTable
            v-if="starActive == 'post'"
            :apiData="starPostList"
            :env="env"
            :post_type="'按公司'"
            :page="starParams.page"
            :page_size="starParams.page_size"
            :post_total="star_total"
            @changePostPage="handlePageChange"
            @cancelStar="handleCancelStar"
            :isFavorites="true"
          ></PostTable>
          <PatentCardList
            v-if="starActive == 'patent'"
            :apiData="starPatentList"
            :showModal="true"
            :total="star_total"
            :page="starParams.page"
            :page_size="starParams.page_size"
            @changePage="handlePageChange"
            @cancelStar="handleCancelStar"
            :isFavorites="true"
          ></PatentCardList>
          <StarCompanyList
            v-if="starActive == 'company'"
            :company_list="starCompanyList"
            :company_total="star_total"
            :page="starParams.page"
            :page_size="starParams.page_size"
            @changePage="handlePageChange"
            @cancelStar="handleCancelStar"
          ></StarCompanyList>
        </div>
      </div>
      <div class="my_info" v-if="currentTab == 4">
        <div class="userInfo-box">
          <!-- <el-radio-group
            v-model="userActiveTab"
            size="small"
            style="position: absolute; left: 15px; top: 6vh"
          >
            <el-radio-button label="基本信息"></el-radio-button>
            <el-radio-button label="权限信息"></el-radio-button>
          </el-radio-group> -->
          <el-tabs
            v-model="permissionActiveTab"
            class="starTab"
            style="margin: 10px 0 0 0"
          >
            <!-- <el-tab-pane label="基本信息" name="基本信息"></el-tab-pane> -->
            <el-tab-pane label="权限信息" name="权限信息"></el-tab-pane>
          </el-tabs>
          <div
            class="user"
            style="
              width: 60px;
              height: 60px;
              margin-bottom: 10px;
              border: 3px solid gray;
              margin-top: 15px;
            "
          >
            <i class="el-icon-user" style="font-size: 35px"></i>
          </div>
          <p style="margin-bottom: 40px">
            {{ userInfoParams.username }}
          </p>
          <div class="userStatus">
            <div class="userStatus-left">账户状态</div>
            <div class="userStatus-right">
              <div class="levelBox">
                当前会员等级：<span class="level">{{ level }}</span>
              </div>
            </div>
          </div>
          <el-form ref="form" label-width="120px" style="margin-top: 40px">
            <el-table
              :data="userTableData"
              border
              style="width: 50vw; color: #333"
            >
              <el-table-column label="权限列表">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <p size="medium">{{ scope.row.perm_name }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="type_zh" label="类型"> </el-table-column>
              <el-table-column prop="effective_time" label="过期时间">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <!-- v-if="scope.row.type == 'vip'" -->
                  <div
                    slot="reference"
                    class="buyVip"
                    @click="buyVip(scope.row.code)"
                    style="cursor: pointer;margin-right: 12%;color: rgba(245,146,27,1);display: flex;align-items: center;"
                  >
                    <img
                      class="buyVip-img"
                      style="width: 18px; height: 17px; margin-right: 5px;"
                      src="@/assets/images/vip.png"
                    />
                    开通VIP / 续约
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span v-if="isDianlian"
        >该功能暂未授权，您可以试用7天，或联系当前操作人员所属企业的管理员进行授权或付费开通。付费联系邮箱：info@suwen.ai</span
      >
      <span v-if="!isDianlian"
        >请联系管理员进行授权或付费开通。联系邮箱：info@suwen.ai</span
      >
      <span slot="footer" class="dialog-footer">
        <span v-if="isDianlian" style="margin-right: 10px">
          <el-button v-if="hasTryPerm" type="primary" @click="trySevenDay"
            >试用 7 天</el-button
          >
          <el-button v-if="!hasTryPerm" type="info" disabled
            >试用 7 天</el-button
          >
        </span>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api";
import NewsList from "@/components/NewsList.vue";
import PostTable from "@/components/PostTable.vue";
import PatentCardList from "@/components/PatentCardList.vue";
import StarCompanyList from "@/components/StarCompanyList.vue";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus";

export default {
  name: "Personal",
  // props: {
  //   apiData: String
  // }
  components: {
    NewsList,
    PostTable,
    PatentCardList,
    StarCompanyList,
  },
  data() {
    return {
      isDianlian: false, // 判断是否点链
      //左边tab栏高亮索引
      personalIndex: 1,
      //是否通过点击开通vip跳转到用户信息界面
      isVipUser: false,
      //用户信息是否完整
      isUserInfo: false,
      hasTryPerm: false,
      activeStatusList: [],
      currentCode: "",
      dialogVisible: false,
      tabBlueActive: 0,
      tabBlueList: [
        {
          index: 0,
          name: "全部",
          count: 0,
        },
        {
          index: 1,
          name: "企业",
          count: 0,
        },
        {
          index: 2,
          name: "产业",
          count: 0,
        },
      ],
      level: "",
      exc_date: "",
      subscribeLoading: false,
      subscribeList: [],
      subscribeListOrigin: [],
      user: "",
      userInfoParams: {
        username: "",
        real_name: "",
        email: "",
        sex: "",
        mobile: "",
        company_name: "",
        department: "",
        post: "",
        province: "",
        city: "",
        interest_industry: "",
        interest_company: "",
        token: "",
      },
      effective_time: "",
      userActiveTab: "基本信息",
      permissionActiveTab: "权限信息",
      SubscribeActiveTab: "订阅列表",
      currentTab: 1,
      tabList: [
        {
          index: 1,
          name: "个人信息",
          image_url: require("@/assets/images/personal_info.png"),
          ref: "one",
        },
        {
          index: 2,
          name: "我的订阅",
          image_url: require("@/assets/images/subscribe.png"),
          ref: "two",
        },
        {
          index: 3,
          name: "我的收藏",
          image_url: require("@/assets/images/star_collection.png"),
          ref: "three",
        },
        {
          index: 4,
          name: "权限信息",
          image_url: require("@/assets/images/unlock.png"),
          ref: "four",
        },
      ],
      env: "",
      userTableData: [],
      showStar: false,
      starActive: "news",
      starLoading: false,
      starNewsList: [],
      starPostList: [],
      starPatentList: [],
      starCompany: [],
      star_total: 0,
      starParams: {
        star_type: "",
        user: "",
        page: 1,
        page_size: 10,
      },
    };
  },
  mounted() {
    this.user = cookie.getCookie("account");
    this.isDianlian =
      cookie.getCookie("dianlian_token") != "undefined" &&
      cookie.getCookie("dianlian_token") != null
        ? true
        : false;
    console.log("dianlian_token", cookie.getCookie("dianlian_token"));
    this.userInfoParams.token = cookie.getCookie("token");
    console.log("token", this.userInfoParams.token);
    // this.showFavorites(user);
    this.getUserInfo();
    this.getActiveStatus();
  },
  methods: {
    checkUserInfo() {
      console.log("userInfoParams", this.userInfoParams);
      let flag = Object.values(this.userInfoParams).every((value) => {
        console.log("checkUserInfo value:", value);
        return value != null && value != "" && value != undefined;
      });
      return flag;
    },
    getActiveStatus() {
      let params = {
        username: cookie.getCookie("account"),
      };
      api.getActiveStatus(params).then((res) => {
        this.activeStatusList = res.data;
      });
    },
    trySevenDay() {
      this.isUserInfo = this.checkUserInfo();
      if (this.isUserInfo) {
        let params = {
          activate_code: this.currentCode,
          username: cookie.getCookie("account"),
        };
        api.activePerm(params).then((res) => {
          if (res.code == 200) {
            this.getUserInfo();
            this.getActiveStatus();
            let vip_perm = JSON.parse(cookie.getCookie("vip_perm"));
            vip_perm.forEach((v) => {
              if (v.codename == this.currentCode) {
                v.has_perm = true;
              }
            });
            cookie.setCookie("vip_perm", JSON.stringify(vip_perm));
            sessionStorage.setItem("active_vip", 1);
            this.$message.success("开通VIP会员成功！");
            this.dialogVisible = false;
          }
        });
      } else {
        this.isVipUser = true;
        this.currentTab = 1;
        this.personalIndex = 1;
        // this.$refs.one[0].$listeners.click()
        this.dialogVisible = false;
        this.$message.warning("填写用户信息后，才可开通vip试用！");
      }
    },
    // 开通会员
    buyVip(val) {
      // this.$message.warning("请联系管理员进行相关操作！")
      // 用户信息完整可以开通vip
      // this.isUserInfo = this.checkUserInfo()
      // if(this.isUserInfo){
      this.dialogVisible = true;
      this.currentCode = val;
      this.activeStatusList.forEach((a) => {
        if (a.code == val) {
          this.hasTryPerm = a.activatable;
        }
      });
      // }
      // else{
      //   this.isVipUser = true
      //   this.currentTab = 1
      //   this.personalIndex = 1
      //   // this.$refs.one[0].$listeners.click()
      //   this.$message.warning("填写用户信息后，才可开通vip试用！")
      // }
      // console.log(val)
    },
    // 点击订阅的内容
    toSubscribeContent(sub) {
      console.log("点击订阅的内容", sub);
      if (sub.subscribe_type == "company") {
        sub.full_name = sub.name;
        this.$router.push("/company_info?row=" + JSON.stringify(sub));
      } else {
        sessionStorage.setItem("subscribe_node", JSON.stringify(sub));
        this.$router.push("/category_graph?from=personal_subscribe");
      }
    },
    // 处理点击tabBlue
    handleClickTabBlue(tab) {
      this.tabBlueActive = tab.index;
      if (tab.index == 0) {
        this.subscribeList = JSON.parse(
          JSON.stringify(this.subscribeListOrigin)
        );
      } else if (tab.index == 1) {
        this.subscribeList = this.subscribeListOrigin.filter(
          (x) => x.subscribe_type == "company"
        );
      } else if (tab.index == 2) {
        this.subscribeList = this.subscribeListOrigin.filter(
          (x) => x.subscribe_type == "option"
        );
      }
    },
    // 获取用户订阅
    getUserSubscribe() {
      let params = {
        account: cookie.getCookie("account"),
        subscribe_type: "option",
      };
      this.subscribeLoading = true;
      api
        .getUserSubscribe(params)
        .then((res) => {
          this.tabBlueList[0].count = res.data.length;
          this.tabBlueList[1].count = 0;
          this.tabBlueList[2].count = 0;
          this.subscribeList = res.data;
          this.subscribeListOrigin = res.data; // 保存订阅的全部原始数据（为了切换分类时不丢失全部数据）
          this.subscribeList.forEach((s) => {
            s.status = true;
            if (s.subscribe_type == "option") {
              this.tabBlueList[2].count++;
            }
            if (s.subscribe_type == "company") {
              this.tabBlueList[1].count++;
            }
          });
          this.subscribeLoading = false;
        })
        .catch((err) => {});
    },
    testSwitch(val) {
      console.log("switch", val);
    },
    // 取消订阅
    cancelSubscribe(val) {
      console.log("取消订阅：", val);
      this.$confirm("您确定要取消订阅吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.$message({
          //   type: "success",
          //   message: "取消订阅成功!",
          // });
          this.cancelLoading = true;
          let params = {
            data_id: val.id,
            account: cookie.getCookie("account"),
            subscribe_type: val.subscribe_type,
          };
          console.log(params);
          api.cancelSubscribe(params).then((res) => {
            console.log("res", res);
            if (res.status == 200) {
              if (this.isFavorites) {
                this.$emit("cancelStar", i.id);
              }
              this.$message.success("取消订阅成功！");
              this.getUserSubscribe();
              this.cancelLoading = false;
            } else {
              this.$message.error("取消订阅失败！");
              this.cancelLoading = false;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
          });
        });
    },
    // 修改用户信息
    changeUserInfo() {
      //检查用户信息是否完整
      this.isUserInfo = this.checkUserInfo();

      // if (!this.userInfoParams.company_name) {
      //   this.$message.warning("企业名未填");
      //   return;
      // } else if (!this.userInfoParams.post) {
      //   this.$message.warning("岗位未填");
      //   return;
      // } else if (!this.userInfoParams.mobile) {
      //   this.$message.warning("电话未填");
      //   return;
      // }
      if (this.isUserInfo) {
        // console.log("修改用户信息", this.userInfoParams)
        api.changeUserBaseInfo(this.userInfoParams).then((res) => {
          console.log("修改用户信息", res);
          if (res.code == 200) {
            this.$message.success("修改用户信息成功！");
            this.getUserInfo();
          } else {
            this.$message.error(`修改用户信息失败！, ${res.errmsg}`);
          }
        });
        if (this.isVipUser) {
          this.isVipUser = false;
          this.trySevenDay();
          this.personalIndex = 4;
          this.currentTab = 4;
          // this.$message.success("请点击开通VIP会员");
        }
      } else {
        this.$message.error("用户信息未填完整！");
      }
    },
    // 获取用户信息
    getUserInfo() {
      let params = {
        username: cookie.getCookie("account"),
        token: cookie.getCookie("token"),
      };
      console.log("用户信息参数22", params);
      api.getUserInfo(params).then((res) => {
        console.log("用户信息22", res);
        this.userTableData = res.user_info.permissions_info;
        this.userTableData.forEach((d) => {
          if (d.type == "normal") {
            d.type_zh = "普通";
          } else if (d.type == "vip") {
            d.type_zh = "VIP";
          }
        });
        // this.userInfoParams.username = res.user_info.username;
        // this.userInfoParams.company_name = res.user_info.company_name;
        // this.userInfoParams.post = res.user_info.post;
        // this.userInfoParams.email = res.user_info.email;
        // this.userInfoParams.telephone = res.user_info.telephone;
        // this.userInfoParams.mobile = res.user_info.mobile;
        // this.userInfoParams.real_name = res.user_info.real_name;
        this.effective_time = res.user_info.effective_time
          ? res.user_info.effective_time.replace("T00:00:00", "")
          : "";
        this.level = res.user_info.vip_info;
        //检查用户信息是否完整
        // this.isUserInfo = this.checkUserInfo()
        // if(this.isUserInfo){
        //   console.log('用户信息完整')
        // }else{
        //   console.log('用户信息不完整')
        // }
      });
      api.getUserBaseInfo(params).then((res) => {
        console.log("用户基本信息：", res);
        this.userInfoParams.username = res.data.username;
        this.userInfoParams.real_name = res.data.real_name;
        this.userInfoParams.email = res.data.email;
        this.userInfoParams.sex = res.data.sex;
        this.userInfoParams.mobile = res.data.mobile;
        this.userInfoParams.company_name = res.data.company_name;
        this.userInfoParams.department = res.data.department;
        this.userInfoParams.post = res.data.post;
        this.userInfoParams.province = res.data.province;
        this.userInfoParams.city = res.data.city;
        this.userInfoParams.interest_industry = res.data.interest_industry;
        this.userInfoParams.interest_company = res.data.interest_company;
      });
    },
    // 点击左侧tab
    handleClickTab(tab) {
      this.personalIndex = tab.index;
      this.currentTab = tab.index;
      console.log("click", this.currentTab, this.personalIndex);
      if (tab.index == 1) {
        this.getUserInfo();
      } else if (tab.index == 3) {
        this.showFavorites(this.user);
      } else if (tab.index == 2) {
        this.getUserSubscribe();
      }
    },
    handleCancelStar() {
      console.log("handleCancelStar");
      this.starParams.page = 1;
      this.getUserStar();
    },
    // 点击加载更多
    handlePageChange(val) {
      this.starParams.page = val;
      this.getUserStar();
    },
    // 加载更多
    showMore() {
      this.starParams.page += 1;
      this.getUserStar();
    },
    // 响应父组件调用
    showFavorites(val) {
      this.starActive = "news";
      this.showStar = true;
      this.starParams.user = val;
      this.starParams.star_type = "news";
      this.starParams.page = 1;
      this.getUserStar();
    },
    // 响应点击收藏tab
    handleStarActive(val) {
      console.log(val.name);
      this.starParams.page = 1;
      this.starParams.star_type = val.name;
      this.getUserStar();
    },
    // 获取收藏内容
    getUserStar() {
      this.starLoading = true;
      api
        .getUserStar(this.starParams)
        .then((res) => {
          console.log(res);
          if (this.starParams.star_type == "news") {
            this.starNewsList = res.data;
          }
          if (this.starParams.star_type == "post") {
            this.starPostList = res.data;
          }
          if (this.starParams.star_type == "patent") {
            this.starPatentList = res.data;
          }
          if (this.starParams.star_type == "company") {
            this.starCompanyList = res.data;
            this.starCompanyList.forEach((i) => {
              i.compNameForLogo = i.short_name
                ? i.short_name.substring(0, 4)
                : i.full_name.substring(3, 7);
            });
          }
          this.star_total = res.count;
          this.starLoading = false;
        })
        .catch((error) => {
          this.starLoading = false;
          this.$message.error(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.personal {
  display: flex;
  .el-form-item {
    margin-bottom: 4px;
  }
  #star {
    .el-dialog__body {
      padding-top: 0px;
    }
  }
  &-menu {
    width: 150px;
    height: 85vh;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
  &-right {
    width: 89.5%;
    margin-left: 1%;
  }
  // .personal {
  //   width: 98%;
  //   // overflow-y: scroll;
  //   // padding-right: 10px;
  //   // padding: 0 10px 0 10px;
  //   margin-left: 10px;
  //   overflow: hidden;
  //   // height: 65vh;
  //   &-content {
  //     // overflow-y: scroll;
  //     height: 60vh;
  //   }
  // }
  .el-menu-item {
    .is-active {
      background-color: #dfeef7;
      color: rgba(18, 150, 219, 1);
    }
  }

  .starTab {
    width: 98%;
  }
  .slide_icon {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
  .userInfo {
    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 99%;
      padding-bottom: 60px;
      // margin-top: 7vh;
      // min-height: 60vh;
      .formInfoBox {
        width: 60%;
        .tableTitle {
          background: #4473c5;
          color: #fff;
          .el-form-item__label {
            color: #fff;
          }
        }
        .tableContent {
          .el-form-item {
            display: flex;
            flex: 1;
            margin: 0;
          }
          .tableContentOdd {
            display: flex;
            height: 40px;
            background-color: #cfd5eb;
            .el-form-item__label {
              // width: 50%;
              flex: 1;
              background: #cfd5eb;
              text-align: left;
              padding-left: 5px;
              padding-right: 0px;
              border-right: 1px solid #fff;
            }
            .contentBorder {
              flex: 1;
              .el-form-item__content {
                border-right: 1px solid #fff;
              }
            }
            .el-form-item__content {
              flex: 1;
            }
            .el-input__inner {
              // flex: 1;
              border-style: none;
              background: #cfd5eb;
              color: #606266;
            }
          }
          .tableContentEven {
            display: flex;
            height: 40px;
            background-color: #e9ecf5;
            .el-form-item__label {
              // width: 50%;
              flex: 1;
              background: #e9ecf5;
              text-align: left;
              padding-left: 5px;
              padding-right: 0px;
              border-right: 1px solid #fff;
            }
            .contentBorder {
              flex: 1;
              .el-form-item__content {
                border-right: 1px solid #fff;
              }
            }
            .el-form-item__content {
              flex: 1;
            }
            .el-input__inner {
              border-style: none;
              background: #e9ecf5;
              color: #606266;
            }
          }
          .tableContentOddlast {
            display: flex;
            height: 40px;
            background-color: #cfd5eb;
            .el-form-item__label {
              // width: 25%;
              flex: 1;
              background: #cfd5eb;
              text-align: left;
              padding-left: 5px;
              padding-right: 0px;
              // border-right: 1px solid #fff;
            }
            .el-form-item__content {
              flex: 3;
              border-left: 1px solid #fff;
              margin-left: -3px;
            }
            .el-input__inner {
              border-style: none;
              background: #cfd5eb;
              color: #606266;
            }
          }
          .tableContentEvenlast {
            display: flex;
            height: 40px;
            background-color: #e9ecf5;
            .el-form-item__label {
              // width: 25%;
              flex: 1;
              background: #e9ecf5;
              text-align: left;
              padding-left: 5px;
              padding-right: 0px;
              // border-right: 1px solid #fff;
            }
            .el-form-item__content {
              flex: 3;
              border-left: 1px solid #fff;
              margin-left: -3px;
            }
            .el-input__inner {
              border-style: none;
              background: #e9ecf5;
              color: #606266;
            }
          }
          .place {
            display: flex;
            .el-input {
              width: 25%;
            }
            .el-input__inner {
              padding: 0;
              padding-left: 10px;
              // text-align: left;
            }
          }
        }
      }
    }
    .el-dialog {
      height: 90vh;
      // position: fixed;
    }
  }
  .user {
    border: 2px solid #65676b;
    cursor: pointer;
    margin-left: 10px;
    // margin-right: 10px;
    border-radius: 50%;
    height: 29px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    [class*=" el-icon-"],
    [class^="el-icon-"] {
      font-size: 20px;
    }
  }
  .my_info {
    position: relative;
  }
  .subscribe {
    &-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px rgb(239, 239, 239) solid;
      margin-bottom: 18px;
      padding-bottom: 10px;
    }
    &-name {
      font-size: 15px;
      cursor: pointer;
      font-weight: bold;
      color: #333333;
    }
  }
  .my_subscribe {
    // padding: 30px;
    padding-left: 10px;
    min-height: 70vh;
  }
  .userStatus {
    width: 50vw;
    height: 40px;
    display: flex;
    &-left {
      width: 10%;
      background-color: #1296e5;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-right {
      width: 90%;
      color: #333;
      align-items: center;
      display: flex;
      justify-content: space-between;
      background-color: #dfeef7;
      .levelBox {
        margin-left: 2%;
      }
      .level {
        color: rgb(64, 158, 255);
      }
      .buyVip {
        cursor: pointer;
        margin-right: 12%;
        color: rgba(245, 146, 27, 1);
        display: flex;
        align-items: center;
        &-img {
          width: 18px;
          height: 17px;
          margin-right: 5px;
        }
      }
    }
  }
  .el-table tr {
    height: 60px;
  }
  td {
    border-style: none;
    border-bottom: 0.5px solid #dfeef7;
    border-right: 0.5px solid #dfeef7;
  }
  .el-table_1_column_2 {
    color: #bd3124;
  }
  .is-leaf {
    color: #333;
  }
  .tabBlue {
    background-color: #eef6fa;
    display: flex;
    align-items: center;
    height: 45px;
    margin-bottom: 10px;
    padding-left: 15px;
    &-item,
    &-item_selected {
      width: 15%;
      cursor: pointer;
    }
    &-item_selected {
      color: rgb(64, 149, 229);
    }
  }
}
</style>
