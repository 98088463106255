<template>
  <div class="starCompanyListWrapper" id="starCompanyListWrapper">
    <div class="starCompanyList">
      <div class="companyCard" v-for="i in company_list" :key="i.company_id">
        <div class="detail">
          <div class="detailTop">
            <!-- <img src="@/assets/img/companyLogo.png" alt /> -->
            <div class="compLogo">
              <p>{{ i.compNameForLogo }}</p>
            </div>
            <div class="des">
              <div class="companyAndScore">
                <p
                  style="font-size: 18px; font-weight: bold; cursor: pointer"
                  @click="selectCompany(i.full_name)"
                >
                  {{ i.full_name }}
                  <!-- <span>股票：{{ companyInfo.stock_code || "暂无" }}</span> -->
                </p>
                <Star
                  class="companyDetailHori-star"
                  :apiData="i"
                  :type="'company'"
                  :isFavorites="true"
                  @cancelStar="handleCancelStar"
                ></Star>
              </div>
              <p>
                <span>电话：{{ i.telephone || "暂无" }}</span>
                <span style="cursor: pointer" @click="goto(i.website)"
                  >官网： {{ i.website || "暂无" }}</span
                >
              </p>
              <p>
                <span>邮箱：{{ i.email || "暂无" }}</span>
                <span>地址：{{ i.address || "暂无" | textCut(15) }}</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <TagList
            :tagList="i.tagList"
            v-if="i.tagList"
            :tagType="'primary'"
          ></TagList>
          <TagList
            :tagList="i.productList"
            v-if="i.productList"
            :tagType="'success'"
          ></TagList>
          <TagList
            :tagList="i.industryPaquList"
            v-if="i.industryPaquList"
            :tagType="'warning'"
          ></TagList>
        </div>
      </div>
      <div class="contentFooter">
        <el-pagination
          background
          layout="prev, pager, next"
          :pager-count="10"
          :current-page="page"
          :page-size="page_size"
          :total="company_total"
          @current-change="handlePageChangeCompany"
        >
        </el-pagination>
      </div>
      <!-- 公司详细信息 -->
      <companyDetailHori ref="companyDetailHori"></companyDetailHori>
    </div>
  </div>
</template>

<script>
import TagList from "@/components/TagList.vue";
import CompanyDetailHori from "@/components/CompanyDetailHori.vue";
import Star from "@/components/Star.vue";
import api from "@/api/api";
import utils from "@/utils/utils";

export default {
  components: {
    TagList,
    CompanyDetailHori,
    Star,
  },
  name: "StarCompanyList",
  data() {
    return {
      // companyParams: {
      //   page: 1,
      //   page_size: 10
      // },
    };
  },
  props: {
    company_list: Array,
    company_total: Number,
    searchValue: String,
    page: Number,
    page_size: Number,
    hasKeyword: String,
    searchType: String,
  },
  watch: {
    company_list: {
      handler(newValue) {
        utils.scrollToTopByClass("newsListWrapper"); // 滚动回顶部
      },
      deep: true,
    },
  },
  methods: {
    // 响应取消收藏
    handleCancelStar(val) {
      this.$emit("cancelStar", 1);
      console.log("响应取消收藏", val);
      // utils.objArrRemove(this.apiNewsList, "id", val)
    },
    // 找下标，删数组内容
    deleteArrayObject(arr, val) {
      let num = null;
      let arr_tmp = JSON.parse(JSON.stringify(arr));
      arr_tmp.forEach((a, index) => {
        if (a.code == val) {
          num = index;
        }
      });
      arr_tmp.splice(num, 1);
      console.log("arr_tmp", arr_tmp);
      return arr_tmp;
    },
    // 选择公司
    selectCompany(full_name) {
      let params = {
        full_name: full_name,
      };
      this.$refs.companyDetailHori.selectCompany(params);
    },
    handlePageChangeCompany(val) {
      // this.companyParams.page = val
      this.page = val;
      this.$emit("changePage", this.page);
    },
    // 打开网页
    goto(web) {
      if (web.indexOf("http") == -1) {
        window.open("http://" + web);
      } else {
        window.open(web);
      }
    },
    // 关键词红色高亮
    highlight(text, keyword, font_size) {
      if (text.indexOf(keyword) != -1) {
        text = text.replace(
          eval(`/${keyword}/g`),
          `<span style="color:red;font-size:${font_size};">${keyword}</span>`
        );
        // console.log("text", text)
        return text;
      } else {
        return text;
      }
    },
    // 修改es的highlight默认样式
    esHL(text) {
      text = text.replace(/<em>/g, '<b style="color:red;">');
      text = text.replace(/<\/em>/g, "</b>");
      // console.log("text", text)
      return text;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.starCompanyListWrapper {
  overflow-y: scroll;
  height: 100%;
}
.starCompanyList {
  width: 70%;
  margin-left: 15%;
  margin-top: 20px;
  &-total {
    display: flex;
    justify-content: space-between;
  }
  .companyCard {
    position: relative;
    width: 100%;
    padding-bottom: 20px;
    // height: 150px;
    margin-bottom: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .detail {
    width: 100%;
    // max-width: 1200px;
    margin-top: 10px;
    &-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      // background-color: rgba(91, 91, 94, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: #d3feff;
        font-size: 30px;
        font-weight: bold;
      }
    }
    .detailTop {
      display: flex;
      // background-color: rgba($color: #000000, $alpha: 0.4);
      // border-bottom: solid silver 1px;
      // img {
      //   margin: 20px 10px 20px 25px;
      //   object-fit: contain;
      //   height: 80px;
      //   width: 80px;
      //   background: white;
      // }
    }
    .compLogo {
      margin: 20px 10px 20px 25px;
      height: 80px;
      width: 80px;
      // background-color: white;
      // border: 1px solid gray;
      background-color: #01a4d9;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        width: 80px;
        overflow: hidden;
        word-break: break-all;
        word-wrap: wrap;
        margin-left: 7px;
        text-align: center;
        color: white;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 8px;
      }
    }
    .des {
      overflow: hidden;
      flex: 1;
      padding: 10px;
      color: black;
      p {
        font-size: 14px;
        margin: 5px 0;
        span {
          display: inline-block;
          width: 48%;
          margin: 0 5px;
        }
      }
      h1 {
        margin: 10px 0;
        font-size: 18px;
        span {
          font-size: 14px;
          margin: 0 5px;
          //   background: #E7F4FF;
          color: cornflowerblue;
        }
      }
    }
  }
  .hit {
    position: absolute;
    top: 10px;
    right: 20px;
    // border: 2px solid #337AB7;
    color: white;
    background-color: #337ab7;
    border-radius: 4px;
    padding: 2px 4px 2px 4px;
  }
  .searchDetail {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    flex-direction: row-reverse;
    &-select {
      width: 30%;
      margin-right: 10px;
    }
  }
  .hitContent {
    margin-left: 20px;
    margin-top: 10px;
    color: #337ab7;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    i {
      margin-right: 5px;
    }
    &-item {
      // margin-bottom: 10px;
      margin-right: 5px;
      // border: 2px solid #337AB7;
      color: white;
      background-color: #337ab7;
      border-radius: 4px;
      padding: 2px 6px 2px 6px;
    }
  }
  .totalText {
    font-size: 13px;
    color: gray;
  }
  .companyAndScore {
    display: flex;
    align-items: center;
    &-score {
      margin-left: 10px;
    }
  }
}
</style>
